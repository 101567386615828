import { NgModule } from '@angular/core';
import { AccordionComponent } from './accordion-component/accordion.component';
import { MaterialModule } from 'src/app/MaterialModule';
import { CommonModule } from '@angular/common';
import {
  DataGridComponent,
  GridFilterModalComponent,
  ColumnSorterComponent,
  DataGridPaginatorComponent,
  GridHeaderComponent,
  GridCellComponent
} from './data-grid';
import { ButtonComponent } from './button/button.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { KeyValueComponent } from './key-value/key-value.component';
import { LsSharedModule } from '@limestone/ls-shared-modules';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { StatusStepperComponent } from '../status-bar/status-stepper/status-stepper.component';
import { StatusStepComponent } from '../status-bar/status-stepper/status-step/status-step.component';
import { StatusStepDialogComponent } from '../status-bar/status-stepper/status-step-dialog/status-step-dialog.component';
import { StatusBarComponent } from '../status-bar/status-bar.component';
import { FilterButtonToggleComponent } from '../filter-button-toggle/filter-button-toggle.component';

@NgModule({
  imports: [MaterialModule, CommonModule, LsSharedModule, CdkStepperModule],
  declarations: [
    AccordionComponent,
    DataGridComponent,
    DataGridPaginatorComponent,
    GridFilterModalComponent,
    GridHeaderComponent,
    GridCellComponent,
    ColumnSorterComponent,
    ButtonComponent,
    ErrorModalComponent,
    KeyValueComponent,
    StatusStepperComponent,
    StatusStepComponent,
    StatusStepDialogComponent,
    StatusBarComponent,
    FilterButtonToggleComponent
  ],
  exports: [
    AccordionComponent,
    DataGridComponent,
    ButtonComponent,
    KeyValueComponent,
    StatusStepperComponent,
    StatusStepComponent,
    StatusStepDialogComponent,
    StatusBarComponent,
    FilterButtonToggleComponent
  ]
})
export class LsComponentModule {}
