<div class="ls-data-grid d-flex flex-column">
  <div class="action-bar d-flex flex-row justify-content-end align-items-center ls-mb-3">
    <button
      color="primary"
      mat-raised-button
      *ngFor="let button of gridButtons; let last = last"
      [class.ls-mr-3]="!last"
      (click)="handleGenericButtonClick($event, button.event)"
      [disabled]="!anySelected()">
      {{button.label}}
    </button>
  </div>
  <!-- Column Sorter -->
  <div class="action-bar d-flex flex-row justify-content-end align-items-center ls-mb-3">
    <button
      class="ls-mr-3"
      color="warn"
      mat-raised-button
      *ngIf="anySelected() && allowDeleting"
      (click)="deleteSelected()">
      <mat-icon>delete</mat-icon> Delete Selected ({{ numberOfSelected() }})
    </button>
    <button class="ls-mr-3" color="accent" mat-raised-button *ngIf="activeFilters$.value?.size > 0" (click)="clearFilters()">
      <mat-icon>clear</mat-icon> Clear Filters
    </button>
    <button [class.ls-mr-3]="showHideColumns" color="primary" mat-raised-button *ngIf="allowAdding" (click)="addRow()" [disabled]="isGridBeingEdited()">
      <mat-icon>add</mat-icon> Add
    </button>
    <button [class.ls-mr-3]="showHideColumns" color="primary" mat-raised-button *ngIf="allowFormAdding" (click)="addRowWithForm()" [disabled]="isGridBeingEdited()">
      <mat-icon>add</mat-icon> Add
    </button>
    <button color="primary" mat-raised-button *ngIf="allowExporting" (click)="handleExport()" [disabled]="!!editIndex">
      Export to Excel
    </button>
    <div
      ls-mat-table-column-sorter
      *ngIf="showHideColumns"
      [(columns)]="columns"
      [dragColumns]="allowDragColumns"
      (columnsChange)="emitColumnsChangedEvent($event)"
      #columnSorter>
      <mat-icon>view_column</mat-icon>
      Columns
    </div>
  </div>

  <!-- Table  -->
  <div class="table-container" [class.ls-mb-3]="pageSizeOptions.length > 0">
    <table cdkDropList
           (cdkDropListDropped)="onListDrop($event)"
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef class="small-col">
          <mat-checkbox
            (change)="$event ? masterToggle($event) : null"
            [checked]="anySelected() && isAllSelected()"
            [indeterminate]="anySelected() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row; let i = dataIndex">
          <mat-checkbox
            (click)="$event.stopPropagation(); shiftSelect($event, i)"
            (change)="select(row, $event)"
            [checked]="isSelected(row)">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!--  Dynamically rendered content  -->
      <ng-container *ngFor="let column of gridColumns" matColumnDef="{{ column.label }}" [sticky]="column.sticky" [stickyEnd]="column.stickyEnd">
        <th mat-header-cell *matHeaderCellDef class="col-space">
          <ls-data-grid-header
            [column]="column"
            [activeFilters$]="activeFilters$"
            [activeSorts$]="activeSorts$"
            [groupByColumns]="groupByColumns"
            (groupToggleClicked)="handleGroupingClick($event)"
            (sortChanged)="emitSortChangedEvent($event)"
            (filterChanged)="emitFilterChangedEvent($event)">
          </ls-data-grid-header>
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex" (click)="allowSingleClickEditing && (allowFormEditing ? editRowWithForm(i) : editRow(i))" (dblclick)="allowFormEditing ? editRowWithForm(i) : editRow(i)">
          <ls-data-grid-cell #gridCellComponent
                             [element]="element"
                             [column]="column"
                             [isEditing]="i === editIndex"
                             [autoCompleteOptions]="autoCompleteMap.get(column)"
                             (dataChange)="emitDataChangedEvent($event, column, element)"
                             (formIsValid)="handleFormStatusChange(column, $event)"
                             (autocompleteChange)="handleAutoCompleteChange(element, $event)"
                             (selectChange)="handleSelectChange($event)"
                             (cellButtonClick)="handleCellButtonClick($event)"
                             (linkClick)="handleLinkClick($event)">
          </ls-data-grid-cell>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <strong>{{ getFooter(column) }}</strong>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="details" stickyEnd>
        <th mat-header-cell *matHeaderCellDef class="small-col"></th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <div class="d-flex flex-row">
            <button
              *ngIf="editIndex === i"
              mat-icon-button
              aria-label="row-edit"
              color="accent"
              (click)="revertData(element)">
              <mat-icon>clear</mat-icon>
            </button>
            <button
              *ngIf="editIndex === i"
              mat-icon-button
              aria-label="row-edit"
              color="primary"
              (keydown.tab)="toggleNextEdit(i)"
              [disabled]="rowIsInValid()"
              (click)="applyEdit()">
              <mat-icon>done</mat-icon>
            </button>
            <button
              *ngIf="editIndex === i && allowDeleting"
              mat-icon-button
              aria-label="row-edit"
              color="warn"
              (click)="submitDelete(element)"
              (keydown.tab)="toggleNext(i)">
              <mat-icon>delete</mat-icon>
            </button>
            <button
              *ngIf="hasDetails(element)"
              mat-icon-button
              aria-label="row-edit"
              color="accent"
              (click)="expandRow($event, element)">
              <mat-icon *ngIf="expandedElement !== element">unfold_more</mat-icon>
              <mat-icon *ngIf="expandedElement === element">unfold_less</mat-icon>
            </button>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="viewableColumns" class="header-row"></tr>
      <tr
        cdkDrag
        [cdkDragDisabled]="!allowDragRows"
        mat-row
        *matRowDef="let row; columns: viewableColumns; when: checkRow"
        class="element-row"
        [class.expanded-row]="expandedElement === row"
        [class.selected-row]="isSelected(row)"
        (click)="select(row)"></tr>
      <!--      <ng-container *ngIf="hasFooter()">-->
      <tr mat-footer-row *matFooterRowDef="viewableColumns" [style.display]="displayFooter ? 'table-row' : 'none'"></tr>
      <!--      </ng-container>-->

      <!--   Grouping Column   -->
      <ng-container matColumnDef="groupHeader">
        <td colspan="999" mat-cell *matCellDef="let group">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <mat-icon class="level-{{ group.level - 1 }}" *ngIf="group.expanded">expand_more</mat-icon>
            <mat-icon class="level-{{ group.level - 1 }}" *ngIf="!group.expanded">chevron_right</mat-icon>
            <strong>{{ group[groupByColumns[group.level - 1]] }}</strong>
          </div>
        </td>
      </ng-container>
      <tr
        mat-row
        *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
        (click)="groupHeaderClick(row)"></tr>

      <!--   More Detail View   -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" colspan="999">
          <div
            *ngIf="hasDetails(element)"
            [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
            class="element-detail">
            <ng-container *ngIf="getChildConfig(element) !== null">
              <ls-data-grid [lsDataGridConfig]="getChildConfig(element)" [gridData]="getChildData(element)"></ls-data-grid>
            </ng-container>
            <ng-container *ngIf="getChildConfig(element) === null">
              <div *ngFor="let key of getChildKeys(element)" class="child-detail-row">
                <h3>{{key | titlecase}}:</h3>
                <span>{{getChildData(element)[key]}}</span>
              </div>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: checkRow" class="detail-row"></tr>
    </table>
  </div>

  <!-- Paginator -->
  <ng-container *ngIf="pageSizeOptions.length > 0">
    <ls-data-grid-paginator #paginatorComponent
      [pageSizeOptions]="pageSizeOptions"
      [length]="totalDataLength"
      [page$]="page$"
      class="paginator"
      (pageSizeChange)="emitPageEvent($event)">
    </ls-data-grid-paginator>
  </ng-container>
</div>
