<div class="cell-container d-flex flex-row justify-content-start align-items-center"
     matTooltip="{{getToolTip()}}">
  <div *ngIf="!isEditing; else editCell">
    <ng-container [ngSwitch]="column.dataType">
      <button *ngSwitchCase="'button'" mat-button (click)="emitCellButtonEvent($event)" [disabled]="!isCellButtonEnabled(column)">
        <mat-icon *ngIf="column.icon" aria-hidden="false" aria-label="Example home icon" title="View Line Items">{{column.icon}}</mat-icon>
        <span *ngIf="!column.icon">{{ getDisplayValue() }}</span>
      </button>
      <div *ngSwitchCase="'more-button'">
        <div *lsFeatureFlag="'Limestone_V2.2.0'">
          <button mat-icon-button [matMenuTriggerFor]="more">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #more="matMenu">
            <button mat-menu-item *ngFor="let button of column.menuButtons; let i = index"
                    (click)="emitCellButtonEvent($event, i)" [disabled]="button.isDisabled(element)">
              <mat-icon *ngIf="button.icon" >{{button.icon}}</mat-icon>
              <span *ngIf="button.text">{{button.text}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngSwitchCase="'menu-button'">
        <div *lsFeatureFlag="'Limestone_V2.2.0'">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>list_alt</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let button of column.menuButtons; let i = index"
                    (click)="emitCellButtonEvent($event, i)" [disabled]="button.isDisabled(element)">
              <mat-icon *ngIf="button.icon">{{button.icon}}</mat-icon>
              <span *ngIf="button.text">{{button.text}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngSwitchDefault>
        <ng-container [ngSwitch]="column.dataType">
          <ng-container *ngSwitchCase="'checkbox'">
            <mat-icon>{{ getDisplayValue() }}</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <a
              class="ls-grid-link"
              (click)="handleLinkClicked($event)">{{ getDisplayValue() }}</a>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ getDisplayValue() }}
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #editCell>
  <div [formGroup]="formGroup" [ngSwitch]="column.dataType">
    <mat-form-field *ngSwitchCase="'date'" appearance="fill">
      <input
        matInput
        [matDatepicker]="picker"
        [formControlName]="this.column.value"
        (keyup.enter)="submitEdit()" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field *ngSwitchCase="'select'" appearance="fill">
      <mat-select (selectionChange)="handleSelectChange()" [formControlName]="this.column.value" [compareWith]="lookupsEqualAndNotNull">
        <mat-option></mat-option>
        <mat-option [value]="option?.value()" *ngFor="let option of column.dropdownOptions">{{
          option?.label()
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngSwitchCase="'autocomplete'" appearance="fill">
      <input
        type="text"
        aria-label="Number"
        matInput
        [formControlName]="this.column.value"
        [matAutocomplete]="auto"
        (keyup)="handleAutocompleteChange()" />
      <mat-autocomplete [displayWith]="autoCompleteDisplayValue" autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          [value]="option?.value()"
          *ngFor="let option of autoCompleteOptions"
          (onSelectionChange)="handleSelectChange()">
          {{ option.label() }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <section *ngSwitchCase="'checkbox'" appearance="fill">
      <mat-checkbox [formControlName]="this.column.value"> </mat-checkbox>
    </section>
    <section *ngSwitchCase="'link'" appearance="fill">
      <a
        class="ls-grid-link"
        [type]="column.dataType"
        [formControlName]="this.column.value"
        (click)="handleLinkClicked($event)"></a>
    </section>
    <mat-form-field *ngSwitchDefault>
      <input
        matInput
        [type]="column.dataType === 'percent' ? 'number' : column.dataType"
        [formControlName]="this.column.value"
        (keyup.enter)="submitEdit()"
        (keydown)="handleInput($event)" />
    </mat-form-field>
    <div class="error-label" *ngIf="controlHasErrors(this.column.value)">
      {{ getErrorMessage(this.column.value) }}
    </div>
  </div>
</ng-template>
